import { hProps } from "@/const/pages.const"
import Img from "./img"
import { appendHost } from "@/const/append";

import styles from "@/components/styles/loginWrapper.module.css"
import { useMemo } from "react"
import { PROD_SITE } from "@/const/prod.const"


const LoginWrapper = () => {

    const imgSrc = useMemo(() => {
        const val = PROD_SITE as any // type
        return val === 3 ? "img_login_orange.png" : val === 2 ? "img_login_blue.png" :"img_login.png"
    }, [])

    const imgBackSrc = useMemo(() => {
        const val = PROD_SITE as any // type
        return val === 3 ? "back_home_orange.png" : val === 2 ? "back_home_blue.png" :"back_home.png"
    }, [])

    return (
        <div className="relative">
            <Img cls={styles.main_bg} style={{ ...hProps, width: "100%" }} src={appendHost(`/images/login/${imgSrc}`)} width={630} />
            <div className={`abs of_cover ${styles.con}`}>
                <div className={styles.rot}>
                    <Img width={100} height={33} src={appendHost(`/images/theme/login/${imgBackSrc}`)} />
                    <div onClick={() => window.open("/main")} className={`abs ${styles.di_text}`}>返回主页</div>
                </div>
            </div>
            <div className={`abs ${styles.header_logo}`}>
                <Img width={200} height={45} src={appendHost("/images/header/logo.png")} />
            </div>
            <div className={`${styles.login_content}`}>
                <span className="point">
                    <a target="_blank" className="a_w" href="https://www.maomiav.com/">MAOMIAV.COM</a>
                </span><span>&nbsp;海外版永久地址，大陆用户无法访问</span>
            </div>
            <div className={`${styles.mb_text}`}>
                遇见下一个X站时代
            </div>
        </div>
    )
}
export default LoginWrapper