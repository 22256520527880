import { PROD_SITE } from '@/const/prod.const';

export const appendHost = (
    url: string, 
    domainSourceUrl: string = '', // Provide a default value
    withBuild: boolean = true
) => {
    // let imgDomain = `https://mabwhfpvtq.1wu6qbek.com/maomi/mm_web_assets/build1/`; // Hardcoded domain
    let imgDomain = `${domainSourceUrl}/`;

    // console.log("test img domain", imgDomain)

    if (!url) return "";

    // Ensure the URL does not start with the domain
    if (url.startsWith(imgDomain)) {
        // Remove the domain from the URL if it is already included
        url = url.replace(imgDomain, '');
    }

    // Avoid adding .txt multiple times
    if (!url.endsWith('.txt')) {
        url = `${url}.txt`;
    }

    if (!withBuild) {
        return `${imgDomain.replace(`/build${PROD_SITE}`, '')}${url}`;
    }

    // console.log(`withBuild loaded img - > ${imgDomain}${url}`);
    // console.log("check appendhost", `${imgDomain}${url}`)
    return `${imgDomain}${url}`;
}
