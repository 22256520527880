import { FC } from "react"

interface IBtnList {
    list: { name: string, event?: () => void }[];
    cls?: string;
    wrappercls?: string;
}

const BtnList: FC<IBtnList> = ({ list, cls, wrappercls }) => {
    return (
        <div className={`fl gap10 ${wrappercls}`}>
            {list.map((x, i) => {
                return <div className={`${cls} point`} key={i} onClick={x.event && x.event}>{x.name}</div>
            })}
        </div>
    )
}
export default BtnList