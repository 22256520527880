import Back from "@/components/back"
import CategoryHead from "@/components/categoryHead"
import { FC, useEffect } from "react"
import Footer from "@/components/footer"
import BuyCard from "@/components/buyCard"
import useRoleAuth from "@/hooks/useRoleAuth"
import { EStatus, PaymentSubject$ } from "@/components/modal/paymentModal"
import u, { ELocalKey } from "@/util"
import { EModalTypes, modalSubject$ } from "@/hooks/useModal"

const UserBuy: FC = () => {
    useRoleAuth();
    useEffect(() => {
        if (u.isVip()) return;
        PaymentSubject$.next({
            status: EStatus.NOBUY
        })
    }, [])

    useEffect(() => {
        let isLoginfromMk = false;
        try {
            isLoginfromMk = u.decrypt(localStorage.getItem(ELocalKey._MKLOGIN))
        } catch {}

        if (isLoginfromMk) {
            localStorage.removeItem(ELocalKey._MKLOGIN)
            return modalSubject$.next({
                id: EModalTypes.MOONCAKE
            })
        }
    }, [])
    return (
        <div>
            <CategoryHead>
                <div className="con_lay mh700">
                    <div className="mt40">
                        <Back>VIP购买</Back>
                    </div>
                    <div className="plr fl justify_center">
                        <BuyCard />
                    </div>
                </div>
                <Footer />
            </CategoryHead>
        </div>
    )
}
export default UserBuy;
