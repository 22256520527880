import React, { FC } from "react";
import Img from "./img";
import { appendHost } from "@/const/append";

import styles from "./styles/selectBar.module.css"

interface ISelectBar {
    list: { img?: string,  name: string, event?: () => void }[],
    value: any;
    onChange?: (e: any, row: any) => void;
    height?: React.CSSProperties;
    idKey: string
}

const SelectBar: FC<ISelectBar> = ({ list, value, onChange, height, idKey }) => {
    return (
        <div className="fl gap10 align_center fl_wrap">
            {list.map((x: any, i) => {
                return <div key={i} onClick={() => onChange && onChange(i, x)} className={`${styles.p_tye} point ${x[idKey] == value && styles.slcted} relative`} style={height}>
                    {x.img && <Img height={33} width={33} src={x.img} />}
                    <div className={`mt3 ${x[idKey] == value && styles.slc_color} ${styles.item_p}`}>{x.name}</div>
                    {
                        x[idKey] == value && <div className={`abs ${styles.r0}`}>
                        <Img height={33} width={33} src={appendHost("/images/user-center/charge/h.png")} />
                    </div>
                    }
                </div>
            })}
        </div>
    )
}
export default SelectBar