import { FC } from "react"
import style from "./styles/back.module.css"

interface IBack {
    children?: React.ReactNode;
    right?: boolean;
    autoWidth?: boolean;
    cls?: string;
    hideIcon?: boolean;
    useWhiteIcon?: boolean; // Add a new prop for the icon color
    onClick?: () => void;
}

const Back: FC<IBack> = ({ children, right, autoWidth, cls, useWhiteIcon, hideIcon, onClick }) => {
    const backImg = useWhiteIcon

? `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAdBJREFUWEftlr9LVmEYhq+bBoeoXBpqyyX/gBSkqcVqsFFTm3NQxLFBSQQHp4h+QZGWig3hKgQhjhGIu4KCs2GQS0u3vPB8cIbwO7/8vsWznfecw3Wd+3necx7R5kNt5nMhUHsCtnuAl8Bf4JGkP2eVuVYB2w+AReBGQO9J2mqJgO1R4CPQEcBpSfPNmryWBGxPRuwN3oSkN83g6XplAduzwPMMbFTSWh54ZQHbr4CJgP0GEnwjL7y0gO1LwAowHLADYETSjyLwUgK2rwOrQH/AdoDHknaLwgsL2O6ON78TsE1gSNJRGXghAdt3A34rYOvAoKR/ZeG5BWwPROxXA/ZB0tMq4MazTbeh7SvAIdAZDy1IelYHPFcCti8DqctT86WjtQKJGCVI2+5aSLyXNFZHCk1L0IDY7os+6Iq1r7H9zr8JMxK3gWWgN9a+h8SvsmnkTiAjkXrhM/Aw1rbTF1HSXhmJwgLRE+lT/Al4EtD9kPhZVKKUQCaNF8BUnB+HxLciEpUEIo0ZYC4DTT+lL3klKguExDjwOgMdl/Q2j0QtAiGRfs1LbRnJMj1xP4bSm7HWuqE0I5HG8ndAGscHJJ2cVYraSpCn3v+750Kg7QmcAqqDhCHrft87AAAAAElFTkSuQmCC`
: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAjhJREFUWEftljuIE0EYx78vDwyIjyaFdtpoFdjZiSCpbHwUZxe9vVhrcYdYWiiKYGElhy9QfJ5oIbaCIGITkDAzpkmjkAVrReEs1iL5y8ispJBkd7Nkm9tuh5n9/fb/zXwMU8EPF8ynLYHcE2g2m00A6wB+12q1k91ud3NamXMVEEIcZ+ZHRLTHQpn5iFLqw0IEhBAdZn5IRNsc/JJS6vqsTZ5LAr7vnyei9RgGYM0Yc2cW/K9okknT5kgprwK4MjGno7V+kfS7cwkIIW4x85qD/WTmjlLqTVJ45gTa7XZ5OBxuEFHgYCGAFWPMxzTwTAKe59VLpdJzIjrqYJ/K5fJyr9f7nBaeWsD3/YPMvAFAOth7Ijqttf6WBZ5KQErZAmBj32cXAnhtjDlFROOs8MQCQoglZrax73SwB1rrs/OA47UzT0Gr1doRRdFXItrtFt3QWl/MA54ogUajsb1arYZEVC9EwEJdCWz9d7k2e18pdS6PFGaWIIZ4nnfYHb/9buyV1np5IZswlpBSHgDwjIgOuZPwrlKp2B7wPWsaiROYSKLOzE+Z+YQb06PRKOj3+1+ySKQWsBDbisMwfALgjIMOmTlQSvXSSmQSmCjJTQAX3PsPAIEx5m0aibkELMj3/ctEdO1fY2FeUUq9TCoxt4A7pqvMfHsCuqq1vptEIhcBC5JSBgAeF3Ili/9UCHHMXUr3uoa1uEtpLGGv5ePx+B4RbUZRtDQYDH5NK0VuJUhS7//N2RIoPIE/bp7AIawLvYQAAAAASUVORK5CYII=`
    return (
        <div onClick={onClick} className={`${autoWidth ? 'w100' : 'mw1100'}`}>
            <div className={`w100 ${style.btop} ${autoWidth ? 'mt10' : ''} ${cls} fl align_center justify_between`}>
                <div onClick={() => {
                    let _l = window.location.pathname;
                    if (_l.indexOf("user") >= 0) {
                        return window.location.href = "/user/info"
                    }
                    if (_l.indexOf("shipin") >= 0) {
                        return window.location.href = "/page/shipin/1"
                    }
                    if (_l.indexOf("cili") >= 0) {
                        return window.location.href = "/page/cili/140"
                    }
                    if (_l.indexOf("tupian") >= 0) {
                        return window.location.href = "/page/tupian/3"
                    }
                    if (_l.indexOf("meinv") >= 0) {
                        return window.location.href = "/page/meinv/4"
                    }
                    if (_l.indexOf("xiaoshuo") >= 0) {
                        return window.location.href = "/page/xiaoshuo/5"
                    }
                    if (_l.indexOf("yousheng") >= 0) {
                        return window.location.href = "/page/yousheng/6"
                    }
                    if (_l.indexOf("vip") >= 0) {
                        return window.location.href = "/page/vip/57"
                    }
                    if (_l.indexOf("topic") >= 0) {
                        return window.location.href = "/topic"
                    }
                    return window.location.href = "/main"
                }} className="point">
                    {!hideIcon && <img  className="w25" src={backImg} alt="" />}
                </div>
                <div className={right ? "text_left mr10" : "mauto"}>
                    {children}
                </div>
            </div>
        </div>
    )
}
export default Back;

