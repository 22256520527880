import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import Img from "@/components/img";
import dlist from "@/const/defaultList.const";
// import useConfBase from "@/hooks/useConfBase";
import u, { ELocalKey } from "@/util";
import useAxios from "@/hooks/useAxios";
import useCategory from "@/hooks/useCategory";
import { useParams } from "react-router-dom";
// import useWindowLoadCallback from "@/hooks/useWindowEffect";
import bannerStyles from "./styles/bannerlist.module.css"

interface IBannerList {
    flag?: 0 | 1;
}

const BannerList: FC<IBannerList> = ({ flag }) => {
    const [dataList, setDataList] = useState<any>(dlist)
    const category = useCategory()
    const { req } = useAxios("index/countads", 'post', true);
    const { id, channel } = useParams()
    // const [config, updateConfBase, getData] = useConfBase();

    // useWindowLoadCallback(() => {
    //     const res = u.getScriptVal(3) as any;
    //     if (res) {
    //         setDataList(res);
    //     }
    // })

    useEffect(() => {
        u.timeoutCb(() => {
            const __data  = u.getStringtifyVal(localStorage.getItem(ELocalKey.__QX));
            if (__data) {
                setDataList(__data)
            }
        });
    }, [])

    // const fetchBannerList = useCallback(async () => {
    //     if (Object.keys(config).length > 0) {
    //         getData(4).then((res: any) => {
    //             if (res) {
    //                 setDataList(res)
    //             }
    //         })
    //     }
    // }, [config])

    // useEffect(() => {
    //     fetchBannerList()
    // }, [fetchBannerList])

    const _memoC = useMemo(() => {
        const list = u.searchIndexIdByChannel(category, channel);
        return list.findIndex((x: any) => x?.id == id)
    }, [category,  channel, id])

    const clickStats = useCallback( async (x: any) => {
        const __val = u.getStatsParams(false, "", "", window.location.pathname) as any;
        if (__val?.c && typeof _memoC === 'number') {
            __val["c"] = x?.idx + 1; //__val["c"] + _memoC 
        }
        req({ ...__val, category: "ads_index", name: x?.url });
        return u.open(x.url)
    }, [_memoC])

    const parseVal = useCallback((val: string) => {
        try {
            const _val = JSON.parse(val);
            return _val
        } catch {
            return []
        }
    }, [])

    const memoData = useMemo(() => {
        return parseVal(dataList?.[flag ? 'shouyehengfu' : 'neiyehengfu']) || []
    }, [flag, dataList, parseVal])

    return (
        <div>
            {memoData.map((x: any, i: number) => {
                return <div key={i} onClick={() => clickStats({ ...x, idx: i })} className={bannerStyles.imgdiv}>
                    <Img errorCheck cls={`fill1 ${bannerStyles.img}`} src={x.img} />
                </div>
            })}
        </div>
    )
}
export default BannerList;