import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import styles from "@/components/styles/list.module.css"
import Img from "@/components/img";
import { appendHost } from "@/const/append";

import u, { ELocalKey, cancelAllPreviousFetchRequests } from "@/util";
import moment from "moment";
import Empty from "@/components/empty";
import useConfBase from "@/hooks/useConfBase";

interface IImageList {
    list: any[];
    idkey: string;
    idValue: string;
    imgUrlkey: string;
    channel: any;
    reset?: boolean;
    is300?: boolean;
    children?: React.ReactNode;
    tag?: string;
    listNumShow?: number;
    isBuy?: boolean;
    parentId?: number;
    isHome?: boolean;
    updateTime?: any;
    topicParentId?: number;
}

const ImageList: FC<IImageList> = ({ list, idkey, idValue, imgUrlkey, channel, reset, is300, children, tag, listNumShow, isBuy, parentId, isHome, updateTime, topicParentId }) => {
    const [newImgList, setNewImgList] = useState<any>([])
    const [config] = useConfBase();
    const [hoverItem, setHoverItem] = useState<number>(-1)
    const [dataList, setDataList] = useState<any[]>([]);
    const [encryptUrls, setEncryptUrls] = useState<string[]>([])
    const [listShow, setListShow] = useState<number>(30)

    const memoList = useMemo(() => {
        return Array.isArray(list) ? list.map((x) => ({ ...x, name: x[idkey], value: x[idValue] })) : []
    }, [list, idkey, idValue,])

    useEffect(() => {
        if (Array.isArray(list) && list.length > 0) {
            const _l = list.map((x) => ({ ...x, name: x[idkey], value: x[idValue] }))
            setDataList(_l)
        }
    }, [list, idkey, idValue,])

    const fmtimg = useCallback(() => {
        if (Array.isArray(dataList) && config?.image_url) {
            const _l = window.location.href
            if (_l.indexOf("main") < 0) {
                cancelAllPreviousFetchRequests()
            }
            if (["vip", "cili", "shipin", "tupian", "meinv", "xiaoshuo", "yousheng", "nvyou", "tese", "remen", "topic", "remen2", "remen3"].includes(channel)) {
                let _size = '?size=208x130'
                if (["vip", "shipin", "cili", "tese", "remen", "topic", "remen2", "remen3"].includes(channel)) {
                    _size = u.isMobile() ? '?size=400x225' : '?size=500x281'
                }
                if (["tupian"].includes(channel)) {
                    _size = u.isMobile() ? '?size=400x225' : '?size=400x225'
                }
                if (["meinv"].includes(channel)) {
                    _size = u.isMobile() ? '?size=400x225' : '?size=400x225'
                }
                if (["nvyou"].includes(channel)) {
                    _size = u.isMobile() ? '?size=400x225' : '?size=400x225'
                }
                if (["yousheng"].includes(channel)) {
                    _size = u.isMobile() ? '?size=70x100' : '?size=110x150'
                }
                //const mulImgs = [config?.image_url, "https://m.shengzhongc.cn", "https://mm.shengzhongc.cn", "https://mmm.shengzhongc.cn"]
                //const randomIdx = Math.floor(Math.random() * mulImgs.length);
                const imgs = dataList.map((x) => `${config?.image_url}/${x[imgUrlkey]}.txt${_size}`)
                console.log('old img -> ', imgs);

                // let vidKeyParam = u.addVidKeyParam();
                let vidKeyParam = '';
                const res = dataList.map((x) => `${config?.image_url}/${x[imgUrlkey]}${vidKeyParam}`)
                console.log('new img -> ', res);
                setEncryptUrls(res)
                setListShow(listNumShow ? listNumShow : dataList.length)
                // await u.fetchInBatches(res, setNewImgList)
            }
        }
    }, [config, channel, imgUrlkey, dataList])

    useLayoutEffect(() => {
        fmtimg()
    }, [fmtimg])

    useEffect(() => {
        if (reset) {
            setNewImgList([])
            setEncryptUrls([])
            fmtimg();
        }
    }, [reset])

    // useEffect(() => {
    //     console.log(encryptUrls, '11')
    // }, [encryptUrls])

    const memoHeight = useCallback((val: any) => {
        const _l = window.location.href;
        if (_l.indexOf("shipin/12") >= 0) {
            return u.isMobile() ? "280px" : "300px"
        }
        if (is300 || val?.thumb?.includes("nvyou")) {
            return "300px"
        }
        if (["中文字幕", "成人动漫"].includes(val?.tags)) {
            return "279px"
        }
        if (channel === "meinv") {
            return "260px"
        }
        if (channel === "tupian") {
            return "271px"
        }
        if (channel === "yousheng") {
            return "150px"
        }
        if (channel === "nvyou") {
            return "300px"
        }
        if (val?.isLs === 0) {
            return "300px"
        }
        if (channel === "cili") {
            const verticalSection = [141, 145, 146, 147, 148];
            const isVerticalSection = verticalSection.some((x: any) => _l.indexOf(x) >= 0);
            const isHorizontalSection = verticalSection.every((x: any) => _l.indexOf(x) < 0)
            return u.isMobile() ? isHorizontalSection ? "225px" : "130px" : isVerticalSection ? "130px" : "279px"
        }
        return u.isMobile() ? "130px" : "100px"
    }, [is300])

    const memoClassName = useMemo(() => {
        if (channel === "xiaoshuo") {
            return styles.xs_con
        }
        if (channel === "yousheng") {
            return styles.ys_con
        }
        if (channel === "tese" || channel === "topic") {
            return styles.ts_con
        }
        return styles.img_con
    }, [channel])

    const memoContainerName = useCallback((val: any) => {
        if (u.isMobile()) {
            return 'i_cvr'
        }
        if (val?.isLs === 0) {
            return ""
        }
        if (["中文字幕", "成人动漫"].includes(val?.tags)) {
            return ""
        }
        if (val?.thumb?.includes("nvyou")) {
            return ""
        }
        if (channel === "nvyou") {
            return ""
        }
        if (channel === "tupian") {
            return ""
        }
        if (channel === "meinv") {
            return ""
        }
        return `i_cvr`
    }, [channel])

    const handleCategoryClick = useCallback((item: any) => {
        const _l = window.location.href
        localStorage.setItem("j", window.location.href);
        if ((channel === "vip" || channel === "remen") && !u.isVip()) {
            return window.open("/user/buy")
        }
        if ((channel === "vip" || channel === "remen") && u.isVip() && u.isLogin()) {
            return window.open(`/s/video/${channel}/${item.id}`)
        }
        const isTrue = ["cili"].includes(channel);
        if (isTrue) {
            return window.open(`/s/detail/${channel}/${item.id}`)
        }
        if (["tupian", "xiaoshuo", "yousheng"].includes(channel)) {
            let key = channel === "tupian" ? "cat_id" : "cid"
            return window.open(`/s/chapter/${channel}/${item[key]}/${item.id}`)
        }
        if (channel === "shipin" || channel === "remen" || channel === "remen2" || channel === "remen3") {
            const isNvyou = item.thumb.includes("nvyou");
            if (isNvyou) {
                return window.open(`/page/nvyou/${item.id}`)
            }
            return window.open(`/s/video/${channel}/${item.id}`)
        }
        if (channel === "meinv") {
            if (_l.indexOf("detail/meinv") >= 0) {
                return window.open(`/s/chapter/${channel}/${item.cat_id}/${item.id}`)
            }
            return window.open(`/s/detail/${channel}/${item.id}`)
        }

        if (channel === "nvyou") {
            return window.open(`/s/video/${channel}/${item.id}`)
        }

        if (channel === "tese") {
            localStorage.setItem(ELocalKey._TESE, u.encrypt(JSON.stringify(item?._info)))
            return window.open(`/s/video/${channel}/${item.id}`)
        }

        if (channel === "topic") {
            if (isHome) {
                if (topicParentId !== 0) {
                    return window.open(`/page/topic/${topicParentId}`)
                } else {
                    return window.open(`/s/video/topic/${item.id}`)
                }
            }

            localStorage.setItem(ELocalKey._TESE, u.encrypt(JSON.stringify(item?._info)))
            if (isBuy) {
                return window.open(`/s/video/${channel}/${item.id}`)
            } else {
                return window.open(`/page/topic/${parentId}`)
            }
        }
    }, [])

    return (
        <div>
            <div className={memoClassName}>
                {Array.isArray(dataList) && dataList.slice(0, listShow).map((x: any, i: any) => {
                    return <div key={i} className={`${styles.list_item}`} onMouseEnter={() => { isBuy ? setHoverItem(i) : setHoverItem(-1); }} onClick={() => handleCategoryClick(x)}>
                        <div className={`relative`} style={{ height: memoHeight(x) }}>
                            <div style={{ position: 'absolute', height: '100%', boxShadow: 'rgba(0, 0, 0, 0.6) 0px -40px 36px -28px inset', width: '100%' }}></div>
                            {!["yousheng", 'xiaoshuo'].includes(channel) && <>
                                <Img reload={reset} encryptUrl={encryptUrls[i]} cls="i_cvr" src={newImgList[i]} >
                                    {(_decryptUrl: any) => {
                                        return <>
                                            {_decryptUrl &&
                                                <Img reload={reset} cls="lazy list" encryptUrl={encryptUrls[i]} style={{ objectFit: memoContainerName(x) ? 'contain' : 'cover', width: "100%", height: "100%" }} src={_decryptUrl} />
                                            }
                                        </>
                                    }}
                                </Img>
                            </>}
                            {tag && <div className={styles.tag}> {tag}</div>}
                            {hoverItem === i && !["tupian", "meinv", "yousheng", 'xiaoshuo'].includes(channel) && <div className={styles.shadow}>
                                <Img src={appendHost("/images/play/play.png")} width={40} height={40} />
                                <div>
                                </div>
                            </div>}
                            {x.duration && <div className={styles.vid_duration}>
                                <div>{u.secondsToHms(x?.duration)}</div>
                            </div>}
                            {channel !== "xiaoshuo" && channel !== "yousheng" && <div className={styles.vid_time}>
                                <div>
                                    {!!updateTime && <>
                                        {u.fmtDateSince(updateTime * 1000)}
                                    </>}
                                    {!(updateTime) && <>
                                        {x?.update_time ? u.fmtDateSince(x?.update_time * 1000) : x?.insert_time ? u.fmtDateSince(x?.insert_time * 1000) : ''}
                                    </>}
                                </div>
                            </div>}
                            {(channel === 'vip' || channel === 'remen') && <div className={`${styles.vipmark} ${hoverItem === i && 'vip_color'}`}>VIP</div>}
                            {channel === "xiaoshuo" && <div>
                                <div className="fl gap10 align_center justify_between">
                                    <div className={styles.xs_title}>{x?.last_chapter[0]?.title || x.description.slice(0, 6)}</div>
                                    <div className={styles.xs_t}>{u.toFmt(x.update_time * 1000)}</div>
                                </div>
                                <div>{x.finish ? '已完结' : '正在连载中'}</div>
                                <div className={styles.xs_d}>{x.description}</div>

                                <div className={`fl align_center gap10 ${styles.xs_b}`}>
                                    <div>
                                        总共{x.chapters}章节
                                    </div>
                                    <div>|</div>
                                    <div>
                                        {x?.last_chapter?.title || '暂无'}
                                    </div>
                                </div>
                            </div>}
                            {channel === "yousheng" && <div className={styles.ys_main}>
                                <Img reload={reset} encryptUrl={encryptUrls[i]} style={{ width: "110px", height: memoHeight(x) }} src={newImgList[i]} errorImg={1} />
                                <div className="grid gap10">
                                    <div className={styles.ys_t}>{x?.last_chapter?.tags || x?.last_chapter?.title}</div>
                                    <div>
                                        <span className={styles.ys_g}>共</span>
                                        <span className={styles.ys_sm}>{x.chapters}</span>
                                        <span className={styles.ys_g}>章节</span></div>
                                    <div className={styles.ys_b}>更新时间：{u.toFmt(x.update_time * 1000)}</div>
                                </div>
                            </div>}
                        </div>
                        <div className="to">{x?.name || x.title}</div>
                        <div>{children}</div>
                    </div>
                })}
            </div>
            {memoList.length === 0 && <Empty />}
        </div>
    )
}
export default ImageList